
  /*    M O O N R I S E  K I N G D O M    */
  
  /* Arrow Button */
  
  .moon-arrow {
    color: black;
  }
      .moon-arrow:hover {
        color: #B27B7F;
      }
  
  .moon-arrow-alt:hover {
    color: #C99A6B;
  }
  
      .moon-arrow-alt {
        color: #B1A16A;
      }
  
/* MOONRISE COLORS */

.moon-hex1 {
    background-color: #195B4E;
  }
  
  .moon-hex2 {
    background-color: #617337;
  }
  
  .moon-hex3 {
    background-color: #B1A16A;
  }
  
  .moon-hex4 {
    background-color: #E1D06E;
  }
  
  .moon-hex5 {
    background-color: #C99A6B;
  }
  
  .moon-hex6 {
    background-color: #B27B7F;
  }
  
      .moon-hex1-alt, .moon-hex3-alt, .moon-hex5-alt {
        background-color: #B27B7F;
      }
  
      .moon-hex2-alt, .moon-hex4-alt, .moon-hex6-alt {
        background-color: #C99A6B;
    }
  
      .moon-hex1-alt p, .moon-hex2-alt p, .moon-hex3-alt p, .moon-hex4-alt p, .moon-hex5-alt p, .moon-hex6-alt p {
        color: #195B4E;
      }
  
  
  /* Title */
  
  #moon-hex-title {
    font-family: 'EB Garamond', serif;
    font-weight: 700;
    color: black;
  }
  
  #moon-title {
    font-family: 'EB Garamond', serif;
    font-weight: 400;
    text-align: left;
    font-size: 10.5vw;
    margin-top: 15px;
    margin-left: 6vw;
    color: black;
  }
  
        #moon-title-colorful {
          font-family: 'EB Garamond', serif;
          font-weight: 400;
          text-align: left;
          font-size: 10.5vw;
          margin-top: 15px;
          margin-left: 10vw;
          color: #B1A16A; 
        }
  
        #moon-hex-title-colorful {
          font-family: 'EB Garamond', serif;
          font-weight: 700;
          color: #E1D06E;
        }
  
  
  /* Background Color */ 
  
  .moon-body {
    background-color: white;
    width: 100vw;
    height: 100vh;
  }
  
        .moon-body-colorful {
          background-color: #195B4E;
          width: 100vw;
          height: 100vh;
        }
  
  
  /* Text: "Try ____ palette." */ 
  
  .moon-try-text, .moon-try-text-colorful {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 4vw;
    color: black; 
  }
  
  .moon-try-text {
    color: black; 
  }
  
        .moon-try-text-colorful {
          color: #B1A16A; 
        }
  
  
  /* Text: "___ Moonrise Kingdom ___ " */ 
  
  .strong-moon-try-text, .strong-moon-try-text-colorful {
    letter-spacing: 1.2px;
    font-weight: 600;
  }
  
  .strong-moon-try-text {
    color: black;
  }
  
        .strong-moon-try-text-colorful {
          color: #E1D06E;
        }
  
  
  /* Border on picture. */ 
  
  .moon-border {
    border: none; 
  }
  
      .moon-border-colorful {
        border: 4px solid #B27B7F; 
      }
  
  
  
  /* Citation */
  
  .moon-citation {
    margin-left: 10vw;
    padding-bottom: 3rem;
    color: black;
  }
  
      .moon-citation-colorful {
        margin-left: 10vw;
        padding-bottom: 3rem;
        color: #E1D06E;
      }
  
      .moon-citation h3, .moon-citation-colorful h3 {
      font-weight: 700;
      font-size: 3.5vw;
      }
  
      .moon-citation h4, .moon-citation-colorful h4 {
      font-weight: 400;
      font-size: 3.33vw;
      }
  
      .moon-citation h6, .moon-citation-colorful h6 {
        font-weight: 400;
        font-size: 2.5vw;
      }

      .moon-citation-colorful h4, .moon-citation-colorful h6 {
        color: #B1A16A;
      }

      .moon-citation h4, .moon-citation h6 {
        color: black;
      }
  
  
   /* Footer */
  
  .moon-footer, .moon-footer-colorful {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3rem;
    text-align: center;
    font-size: 0.75rem;
    letter-spacing: 1.15px;
  }
  
  .moon-footer {
    background-color: black;
    color: white;
  }
  
      .moon-footer-colorful {
        background-color: #B27B7F;
        color: #E1D06E;
      }
  
      .moon-footer h5, .moon-footer-colorful h5 {
        padding-top: 0.25rem;
        font-weight: 600;
      }
  
      .moon-footer h6, .moon-footer-colorful h6 {
        padding-bottom: 0.25rem;
      } 
  
.moon-horizontal-line {
  clear: both;
  display: block;
  width: 75%;               
  background-color: black;
  height: 2.5px;
  margin: auto;
  margin-bottom: 10px;
}

      .moon-alt-horizontal-line {
        clear: both;
        display: block;
        width: 75%;               
        background-color: #C99A6B;
        height: 2.5px;
        margin: auto;
        margin-bottom: 10px;
      }