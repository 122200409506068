/*  T H E  G R A N D  B U D A P E S T  H O T E L  */
  
  /* Arrow Button */
  
  .budapest-arrow {
    color: black;
  }

  .budapest-arrow-alt {
    color: #900403;
  }
  
      .budapest-arrow:hover {
        color: #D8AA88;
      }
      
      .budapest-arrow-alt:hover {
        color: #C76B4F;
      }

  
  /* Hex Codes */
  
  .buda-hex1 {
    background-color: #4B0100;
  }
  
  .buda-hex2 {
    background-color: #900403;
  }
  
  .buda-hex3 {
    background-color: #4E1042;
  }
  
  .buda-hex4 {
    background-color: #806150;
  }
  
  .buda-hex5 {
    background-color: #C76B4F;
  }
  
  .buda-hex6 {
    background-color: #D8AA88;
  }
  
      .buda-hex1-alt, .buda-hex3-alt, .buda-hex5-alt {
        background-color: #4E1042;
      }
  
      .buda-hex2-alt, .buda-hex4-alt, .buda-hex6-alt {
        background-color: #4B0100;
    }
  
      .buda-hex1-alt p, .buda-hex2-alt p, .buda-hex3-alt p, .buda-hex4-alt p, .buda-hex5-alt p, .buda-hex6-alt p {
        color: #D8AA88;
      }
  
  
  /* Title */
  
  #buda-hex-title {
    font-family: 'EB Garamond', serif;
    font-weight: 700;
    color: black;
  }
  
  #buda-title {
    font-family: 'EB Garamond', serif;
    font-weight: 400;
    text-align: left;
    font-size: 10.5vw;
    margin-top: 15px;
    margin-left: 6vw;
    color: black;
  }
  
  
        #buda-title-colorful {
          font-family: 'EB Garamond', serif;
          font-weight: 400;
          text-align: left;
          font-size: 10.5vw;
          margin-top: 15px;
          margin-left: 10vw;
          color: #900403; 
        }
  
        #buda-hex-title-colorful {
          font-family: 'EB Garamond', serif;
          font-weight: 700;
          color: #4E1042;
        }
  
  
  /* Background Color */ 
  
  .buda-body {
    background-color: white;
    height: 100vh;
    width: 100vw;
  }
  
        .buda-body-colorful {
          background-color: #D8AA88;
          height: 100vh;
          width: 100vw;
        }
  
  
  /* Text: "Try ____ palette." */ 
  
  .buda-try-text, .buda-try-text-colorful {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 4vw;
    color: black; 
  }
  
  .buda-try-text {
    color: black; 
  }
  
        .buda-try-text-colorful {
          color: #900403; 
        }
  
  
  /* Text: "___ The Grand Budapest Hotel ___ " */ 
  
  .strong-buda-try-text, .strong-buda-try-text-colorful {
    letter-spacing: 1.2px;
    font-weight: 600;
  }
  
  .strong-buda-try-text {
    color: black;
  }
  
        .strong-buda-try-text-colorful {
          color: #C76B4F;
        }
  
  
  /* Border on picture. */ 
  
  .buda-border {
    border: none;
  }
  
      .buda-border-colorful {
        border: 4px solid #4E1042; 
      }

  
  /* Citation */
  
  .buda-citation {
    margin-left: 10vw;
    padding-bottom: 3rem;
    color: black;
  }
  
      .buda-citation-colorful {
        margin-left: 10vw;
        padding-bottom: 3rem;
        color: #900403;
      }
  
      .buda-citation h3, .buda-citation-colorful h3 {
      font-weight: 700;
      font-size: 3.5vw;
      }
  
      .buda-citation h4, .buda-citation-colorful h4 {
      font-weight: 400;
      font-size: 3.33vw;
      }
  
      .buda-citation h6, .buda-citation-colorful h6 {
        font-weight: 400;
        font-size: 2.5vw;
      }
  
  
   /* Footer */
  
  .buda-footer, .buda-footer-colorful {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3rem;
    text-align: center;
    font-size: 0.75rem;
    letter-spacing: 1.15px;
  }
  
  .buda-footer {
    background-color: black;
    color: white;
  }
  
      .buda-footer-colorful {
        background-color: #4B0100;
        color: #D8AA88;
      }
  
      .buda-footer h5, .buda-footer-colorful h5 {
        padding-top: 0.25rem;
        font-weight: 600;
      }
  
      .buda-footer h6, .buda-footer-colorful h6 {
        padding-bottom: 0.25rem;
      } 


.buda-horizontal-line {
  clear: both;
  display: block;
  width: 75%;               
  background-color: black;
  height: 2.5px;
  margin: auto;
  margin-bottom: 10px;
}

      .buda-alt-horizontal-line {
        clear: both;
        display: block;
        width: 75%;               
        background-color: #C76B4F;
        height: 2.5px;
        margin: auto;
        margin-bottom: 10px;
      }