/* Style Reset */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 'EB Garamond', serif;
  font-weight: 400;
}

#page-container {
  position: relative;
  height: 100vh;
}

#content-container {
  padding-bottom: 3rem
}

/* Main HexAnderson Title Styles */

#hex-title {
  font-family: 'EB Garamond', serif;
  font-weight: 700;
  color: black;
}

#title {
  font-family: 'EB Garamond', serif;
  font-weight: 400;
  text-align: left;
  font-size: 10.5vw;
  margin-top: 15px;
  margin-left: 6vw;
  color: black;
}

/* Rainbow Effect on HexAnderson Title */

#hex-title .h-letter:hover {
  color: #0D6CE8;
}

#hex-title .e-letter:hover {
  color: #8ACAF0;
}

#hex-title .x-letter:hover {
  color: #687075;
}

.a-letter:hover {
  color: #617337;
}

.n-letter:hover {
  color: #195B4E;
}

.d-letter:hover {
  color: #CBB22F;
}

.e2-letter:hover {
  color: #E0A517;
}

.r-letter:hover {
  color: #C68130;
}

.s-letter:hover {
  color: #900403;
}

.o-letter:hover {
  color: #4B0100;
}

.n2-letter:hover {
  color: #4E1042;
}


.homepage {
  font-size: 3.5vw;
  text-align: center;
}

/* MAIN PAGE SMALL IMAGES */

#budapest-main-image, #moonrise-main-image, #chevalier-main-image,  #darjeeling-main-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
}

#photo-wrapper {
display: grid; 
grid-template-columns: 1fr 1fr;
grid-template-rows: 1fr 1fr;
grid-gap: 10px;
justify-items: center;
align-items: center;
}

.budapest-small-image {
  width: 35vw;
  max-width: 400px;
  grid-row: 1 / 2;
  grid-column: 1 / 2; 
}

.moonrise-small-image {
  width: 36vw;
  max-width: 400px;
  grid-row: 1 / 2;
  grid-column: 2 / 3; 
}

.chevalier-small-image {
  width: 35vw;
  max-width: 400px;
  grid-row: 2 / 3;
  grid-column: 1 / 2; 
}

.darjeeling-small-image {
  width: 36vw;
  max-width: 400px;
  grid-row: 2 / 3;
  grid-column: 2 / 3; 
}

.budapest-small-image:hover, .moonrise-small-image:hover, .chevalier-small-image:hover, .darjeeling-small-image:hover {
  opacity: 0.6; 
}


/* GENERAL HEX CODE STYLING */

#budapest-hexcodes, #moonrise-hexcodes, #chevalier-hexcodes, #darjeeling-hexcodes {
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 10vw;
  margin-right: 10vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 2px;
}

.hex1, .hex2, .hex3, .hex4, .hex5, .hex6 {
  height: 20vw;
  width: 12vw;
}

.hex1:hover, .hex2:hover, .hex3:hover, .hex4:hover, .hex5:hover, .hex6:hover {
  opacity: 0.85;
}

.hex1 p, .hex2 p, .hex3 p, .hex4 p, .hex5 p, .hex6 p {
  color: white;
  text-align: center;
  font-size: 3.25vw;
  padding-top: 5px;
  padding-left: 2px;
  font-weight: 400;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  letter-spacing: 1.25px;
}

  
/* ARROW BUTTON */
  
  .fa-arrow-alt-circle-left {
    font-size: 8vw;
    color: black;
    text-decoration: none;
  }
  
  .fa-arrow-alt-circle-up {
    font-size: 6vw;
  }

  .taylor-arrow {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .taylor-arrow:hover {
    color: #E0A517;
  }

  
/* Currently unable to change on specific pages. */

/* Eye Button! */ 

  .eye {
    font-size: 6vw;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }


/* Header Text */ 

  .main-header {
    color: black;
    font-size: 4vw;
  }

  .horizontal-line {
      clear: both;
      display: block;
      width: 75%;               
      background-color: black;
      height: 2.5px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
      margin-top: 10px;
    }


  .horizontal-line-taylor {
    clear: both;
    display: block;
    width: 75%;               
    background-color: black;
    height: 2.5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    margin-top: 10px;
  }

.view-all p {
  font-family: 'EB Garamond', serif;
  font-weight: 500;
  color: black;
  text-align: right;
  margin-right: 12vw;
  margin-top: 8vw;
  font-size: 4vw;
}

.view-all p:hover {
  color: #0D6CE8;
}


.meet-taylor p {
  font-family: 'EB Garamond', serif;
  font-weight: 500;
  color: black;
  text-align: right;
  margin-right: 6vw;
  margin-top: 2vw;
  font-size: 4vw;
}

.meet-taylor p:hover {
  color: #E0A517;
}

#taylor-headshot {
  width: 30vw;
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 2px 5px #888888;
}

.intro-text {
  font-family: 'EB Garamond', serif;
  font-weight: 500;
  font-size: 7vw;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  margin-top: 10px;
}

.bio-text {
  font-family: 'EB Garamond', serif;
  font-weight: 300;
  font-size: 3.5vw;
  text-align: left;
  margin: auto;
  width: 70vw;
  margin-top: 10px;
}

.linkedin {
  text-decoration: none;
  font-weight: 500;
  color: black;
}

.linkedin:hover {
  color: #E0A517;
}

   /* Footer */
  
   .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3rem;
    text-align: center;
    font-size: 0.75rem;
    letter-spacing: 1.15px;
  }
  
  .footer {
    background-color: black;
    color: white;
  }
  
  .footer h5 {
    padding-top: 0.25rem;
    font-weight: 600;
  }
  
  .footer h6 {
    padding-bottom: 0.25rem;
  } 

  .heart {
    color: #E0A517;
  }